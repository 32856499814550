<template>
  <div class="tw-text-center">
    <v-img
      contain
      :aspect-ratio="1.5"
      height="150"
      class="tw-cursor-pointer"
      :src="thumbnail"
    />
    <v-dialog v-model="modalShowThumb" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          rounded
          v-bind="attrs"
          v-on="on"
          color="primary"
          class="tw-tracking-normal tw-capitalize tw-font-medium tw-text-sm tw-mt-4 tw-px-6"
        >
          <v-icon class="mr-2">mdi-image-outline</v-icon>
          Edit Thumbnail
        </v-btn>
      </template>
      <v-card tile>
        <v-img contain :aspect-ratio="1.5" :src="thumbnail"></v-img>
        <div
          class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-20 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
        >
          <v-btn icon dark @click="modalShowThumb = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="(modalShowThumb = false), (modalEditThumb = true)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalEditThumb"
      transition="dialog-bottom-transition"
      max-width="500"
    >
      <v-card>
        <div class="tw-p-4">
          <vue-dropzone
            id="dropzone"
            ref="myVueDropzone"
            :options="dropzoneOptions"
            @vdropzone-success="onComplete"
            @vdropzone-error="showError"
            v-on:vdropzone-sending="sendingEvent"
            :useCustomSlot="true"
          >
            <div class="dropzone-custom-content">
              <span class="dropzone-custom-title">
                Upload image here
              </span>
            </div>
          </vue-dropzone>
          <v-alert
            dense
            border="left"
            type="error"
            class="mt-2"
            v-if="errors.image"
          >
            {{ errors.image }}
          </v-alert>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            outlined
            class="tw-capitalize tw-tracking-wide"
            color="primary"
            max-width="200"
            width="120"
            type="submit"
            @click="modalEditThumb = false"
            >Cancel</v-btn
          >
          <v-btn
            depressed
            class="tw-capitalize tw-tracking-wide"
            color="primary"
            max-width="200"
            width="120"
            type="submit"
            :loading="loading"
            @click.prevent="triggerSend()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import form from "@/mixins/form";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  mixins: [form],
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    thumbnail: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    vendorId: {
      type: Number,
      required: true,
    },
    getDetail: {
      type: Function
    }
  },
  data() {
    return {
      modalShowThumb: false,
      modalEditThumb: false,
      dropzoneOptions: {
        autoProcessQueue: false,
        removeAllFiles: true,
        addRemoveLinks: true,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: "image/*,.svg",
        headers: { Authorization: "Bearer " + this.$store.state.auth.token },
        url:
          `${process.env.VUE_APP_API}/vendors/admin/edit-vendor-thumbnail`,
      },
    };
  },
  methods: {
    triggerSend() {
      this.$store.dispatch("auth/setLoading");
      this.$refs.myVueDropzone.processQueue();
    },
    sendingEvent(file, xhr, formData) {
      formData.append("user_id", this.userId);
      formData.append("vendor_id", this.vendorId);
    },
    async onComplete() {
      this.modalEditThumb = false;
      await this.getDetail()
      this.$refs.myVueDropzone.removeAllFiles();
      this.$store.dispatch("auth/setLoading");
    },
    showError(file, message) {
      if (message.status === "Error") {
        this.errors = {
          image: message.message,
        };
      }
      console.log(message);
    },
  },
};
</script>

<style>
#dropzone .dz-preview .dz-image > img {
  height: 200px !important;
}

.vue-dropzone > .dz-preview .dz-remove {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.dropzone .dz-preview .dz-error-message {
  padding: 0.5em 0.7em !important;
  font-size: 11px !important;
}

.vue-dropzone {
  height: 250px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
</style>
