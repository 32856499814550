<template>
  <v-container fluid>
    <loading-overlay :loading="loading" />

    <v-card tile flat>
      <skeleton-page-heading v-if="!datas" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <h2
          :class="
            $vuetify.breakpoint.mdAndUp
              ? 'tw-text-gray-800 tw-text-xl tw-flex tw-font-medium tw-capitalize'
              : 'tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize'
          "
        >
          <v-icon class="tw-text-gray-800 tw-mr-2"
            >mdi-account-edit-outline</v-icon
          >
          {{ title }}
        </h2>
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <skeleton-form :count="6" v-if="!datas" />
            <v-form v-else @submit.prevent="submit">
              <v-text-field
                v-model="form.user.name"
                label="Nama"
                placeholder="Nama"
                dense
                outlined
                autofocus
                :error-messages="errors.name"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.user.email"
                label="Email"
                placeholder="Email"
                dense
                outlined
                type="email"
                :error-messages="errors.email"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.user.username"
                label="Username"
                placeholder="Username"
                dense
                outlined
                :error-messages="errors.username"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.user.phone_number"
                label="Nomer Telepon"
                placeholder="Nomer Telepon"
                dense
                outlined
                :error-messages="errors.phone_number"
                :disabled="loading"
              ></v-text-field>
              <v-text-field
                v-model="form.whatsapp_number"
                label="Nomer WhatsApp"
                placeholder="Nomer WhatsApp"
                dense
                outlined
                :error-messages="errors.whatsapp_number"
                :disabled="loading"
              ></v-text-field>
              <v-textarea
                v-model="form.address"
                label="Alamat"
                placeholder="Alamat"
                dense
                auto-grow
                outlined
                rows="4"
                :error-messages="errors.address"
                :disabled="loading"
              ></v-textarea>
              <v-select
                v-model="form.booth_type_id"
                label="Booth Type"
                dense
                outlined
                :items="boothTypes"
                item-text="type"
                item-value="id"
                :disabled="loading"
                @change="getDetailBoothType"
                :error-messages="errors.booth_type"
              ></v-select>
              <div>
                <v-skeleton-loader
                  v-if="Object.keys(selectedBooth).length === 0"
                  class="mx-auto"
                  width="250"
                  type="image"
                ></v-skeleton-loader>
                <v-img
                  contain
                  v-else
                  :src="selectedBooth.booth_thumbnail"
                ></v-img>
              </div>
              <div class="tw-text-right tw-mt-4">
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide tw-mr-2"
                  color="primary"
                  max-width="200"
                  width="120"
                  @click="editBoothType"
                  :loading="loading"
                >
                  <v-icon class="tw-mr-2">mdi-pencil-outline</v-icon>
                  Edit Booth
                </v-btn>
                <v-btn
                  depressed
                  class="tw-capitalize tw-tracking-wide"
                  color="primary"
                  max-width="200"
                  width="120"
                  type="submit"
                  :loading="loading"
                  >Submit</v-btn
                >
              </div>
            </v-form>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="5">
            <v-skeleton-loader
              v-if="!datas"
              class="mx-auto"
              width="250"
              type="image"
            ></v-skeleton-loader>
            <div v-else>
              <h4
                class="tw-text-gray-800 tw-text-base tw-flex tw-font-medium tw-capitalize"
              >
                Edit Photos
              </h4>
              <v-divider class="tw-mt-2 tw-mb-5" />
              <admin-modal-edit-logo
                :logo="form.logo"
                :vendor-id="form.id"
                :user-id="form.user.id"
                :get-detail="getDetail"
              />
              <admin-modal-edit-thumb
                :thumbnail="form.thumbnail"
                :vendor-id="form.id"
                :user-id="form.user.id"
                :get-detail="getDetail"
                class="tw-mt-6"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import form from "@/mixins/form";
import page from "../mixins/page";
import Swal from "sweetalert2";
import { detail, update, editBoothType } from "@/api/developer";
import { boothTypes, detailBoothType } from "@/api/booth";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";
import SkeletonForm from "../components/SkeletonForm.vue";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import AdminModalEditLogo from "../components/AdminModalEditLogo.vue";
import AdminModalEditThumb from "../components/AdminModalEditThumb.vue";
export default {
  components: {
    BreadcrumbComponent,
    SkeletonPageHeading,
    SkeletonForm,
    LoadingOverlay,
    AdminModalEditLogo,
    AdminModalEditThumb,
  },
  mixins: [form, page],
  data() {
    return {
      datas: "",
      boothTypes: [],
      selectedBooth: {},
    };
  },
  computed: {
    form: {
      get() {
        return this.datas;
      },
    },
  },
  async created() {
    await this.getDetail();
    await this.getBootTypes();
    await this.getDetailBoothType();
  },
  methods: {
    async submit() {
      try {
        this.$store.dispatch("auth/setLoading");

        await update(this.form);
        this.$store.dispatch("auth/setLoading");
        this.errors = {};
        this.$router.push({ name: "developer" });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
    async getDetail() {
      const {
        data: { data },
      } = await detail(this.$route.params.id);

      const booth_type_id = parseInt(data.booth_type_id);
      this.datas = data;
      this.datas.booth_type_id = booth_type_id;
    },
    async getBootTypes() {
      const {
        data: { data },
      } = await boothTypes();

      this.boothTypes = data;
    },
    async getDetailBoothType() {
      this.selectedBooth = {};
      const data = await detailBoothType({ id: this.form.booth_type_id });

      this.selectedBooth = data;
    },

    async editBoothType() {
      const {data} = await editBoothType({
        vendor_id: this.form.id,
        booth_type_id: this.form.booth_type_id,
      });

      await Swal.fire({
        icon: "success",
        title: data.message,
        timer: 1500,
      });
    },
  },
};
</script>

<style></style>
